import { useAnalyticsOnRunClick as CE_useAnalyticsOnRunClick } from "ce/PluginActionEditor/hooks/useAnalyticsOnRunClick";
import { useCallback } from "react";
import { usePluginActionContext } from "PluginActionEditor/PluginActionContext";
import AnalyticsUtil from "ee/utils/AnalyticsUtil";
import { useLocation } from "react-router";
import { getIDETypeByUrl } from "ee/entities/IDE/utils";
import { IDE_TYPE } from "ee/entities/IDE/constants";

function useAnalyticsOnRunClick() {
  const { action, datasource, plugin } = usePluginActionContext();
  const { pathname } = useLocation();
  const IDEType = getIDETypeByUrl(pathname);
  const { callRunActionAnalytics: CE_callRunActionAnalytics } =
    CE_useAnalyticsOnRunClick();

  const actionId = action.id;
  const actionName = action.name;
  const datasourceId = datasource?.id;
  const pluginName = plugin.name;
  const isMock = !!datasource?.isMock || false; // as mock db exists only for postgres and mongo plugins

  const callRunActionAnalytics = useCallback(() => {
    switch (IDEType) {
      case IDE_TYPE.None:
      case IDE_TYPE.App:
        CE_callRunActionAnalytics();
        break;
      case IDE_TYPE.Package:
        AnalyticsUtil.logEvent("RUN_ACTION_CLICK", {
          actionId,
          actionName,
          datasourceId,
          moduleId: action.moduleId,
          pluginName,
          isMock,
        });
        break;
      case IDE_TYPE.Workflow:
        AnalyticsUtil.logEvent("RUN_ACTION_CLICK", {
          actionId,
          actionName,
          datasourceId,
          pluginName,
          workflowId: action.workflowId,
          isMock,
        });
        break;
    }
  }, [
    actionId,
    actionName,
    datasourceId,
    pluginName,
    isMock,
    IDEType,
    action.moduleId,
    action.workflowId,
  ]);

  return { callRunActionAnalytics };
}

export { useAnalyticsOnRunClick };
