import type { Action, PluginType } from "entities/Action";
import { useModuleFallbackSettingsForm } from "./useModuleFallbackSettingsForm";
import { useSelector } from "react-redux";
import { getModuleById } from "ee/selectors/modulesSelector";

export const useModuleActionSettings = (action?: Action) => {
  const fallbackSettingsForModules = useModuleFallbackSettingsForm({
    pluginId: action?.pluginId || "",
    pluginType: (action?.pluginType as PluginType) || "",
    interfaceType: "CREATOR",
  });

  const module = useSelector((state) =>
    getModuleById(state, action?.moduleId || ""),
  );

  const moduleSettingsConfig =
    module?.settingsForm.length === 0
      ? fallbackSettingsForModules
      : module?.settingsForm;

  return moduleSettingsConfig;
};
